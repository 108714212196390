import React, { useEffect } from 'react';
//import '../assets/css/presentation.css'; // Example import statement

const Assets = () => {
  console.log("Presentation CSS loaded");
  useEffect(() => {
    // Dynamically import the CSS file
    import('../assets/css/presentation.css')
      .then(() => {
        console.log('Presentation CSS loaded');
      })
      .catch((err) => {
        console.error('Error loading the CSS', err);
      });
  }, []); 
  // useEffect(() => {
  //   console.log("innnnnneee",window.innerHeight , window.innerWidth)
  //   function handleRevealOrientation() {
  //   //  if (document.body.classList.contains('reveal-viewport')) {
        
  //       if (window.innerHeight > window.innerWidth) {
  //         alert("Please rotate your device to landscape mode for the best presentation experience.");
  //       }
  //     //}
  //   }

  //   window.addEventListener('resize', handleRevealOrientation);
  //   handleRevealOrientation(); // Initial check

  //   // Cleanup event listener on component unmount
  //   return () => {
  //     window.removeEventListener('resize', handleRevealOrientation);
  //   };
  // }, []);

  return (
    <>
      {/* External CSS */}
      {/* <link rel="stylesheet" type="text/css" href="../assets/css/presentation.css" /> */}
    </>
  );
};

export default Assets;
