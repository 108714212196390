import React from 'react';
//import '../assets/css/index.css'; // Example import statement
//import '../assets/css/index.css';  it was included everywhere

const Assets = () => {
  return (
    <div>
      {/* External CSS */}
      <link rel="stylesheet" type="text/css" href="../assets/css/index.css" />
      {/* External JavaScript libraries */}
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>
      {/* Other assets if needed */}
    </div>
  );
};

export default Assets;
