import React, { useState, useEffect } from "react";
import { apiUrls } from "../utils/apiUrls";
import { callAPI } from "../utils/apiUtils";

const ChildModal = ({ closeModal, editFileId, editTitle, editAction, activeNavItem, collectEmail, userDetail, setActiveNavItem, onCompleteAction, showAlert}) => {
   const [newTitle, setNewTitle] = useState(editTitle);
   const [updatedText, setUpdatedText] = useState("");
   const [isSuccessFeedback, setisSuccessFeedback] = useState(false);
   
   const handleCloseModal = () => {
      closeModal(); // Close the modal using the function passed as prop
    };
    const seePlans = () => {
      closeModal();
      setActiveNavItem("Payment"); // Call the setActiveNavItem function passed from parent with the selected navigation item
    };

    const submitFeedback = async (editAction) => {
      console.log(editAction)
      
      let userId=localStorage.getItem("userId")
        if(userId) {
          const formData = new FormData();
      formData.append("updatedText",updatedText);
      formData.append("userId",userId);
      const headers={
        "Content-Type": "application/json",
        }
        if(editAction === 'feedback') {  
          const response = await callAPI(apiUrls.SUBMITFEEDBACK, {}, "POST",formData,{},headers);
          setisSuccessFeedback(true);
        } else if(editAction === 'customprompt') {
          onCompleteAction(updatedText)
          //const response = await callAPI(apiUrls.SUBMITFEEDBACK, {}, "POST",formData,{},headers);
          closeModal()
        }
       
        }
    };
    
    
    const handleUpdate = async () => {
      const formData = new FormData();
      //formdata.append("file", files[0].file);
      formData.append("editTitle",newTitle);
      formData.append("fileId", editFileId);
      const headers={
        "Content-Type": "application/json",
        }
        if(activeNavItem === 'Presenters') {
          const response = await callAPI(apiUrls.RENAMEPRESENTER, {}, "POST",formData,{},headers);
        } else {
          const response = await callAPI(apiUrls.RENAMEDECK, {}, "POST",formData,{},headers);
        }
      
      closeModal();
    };

    const handleDelete = async () => {
      const formData = new FormData();
      //formdata.append("file", files[0].file);
      let userId=localStorage.getItem("userId")
      formData.append("fileId", editFileId);
      formData.append("userId",userId);
      const headers={
        "Content-Type": "application/json",
        }
      if(activeNavItem === 'Presenters') { 
        const response = await callAPI(apiUrls.DELETEPRESENTER, {}, "POST",formData,{},headers);
      } else {  
        const response = await callAPI(apiUrls.DELETEDECK, {}, "POST",formData,{},headers);
      }
      closeModal();
    };

    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState("");
    const handleEmailSubmit = () => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (email.trim() !== '') {
        if (emailRegex.test(email.trim())) {
          collectEmail(email);
        } else {
          setErrorMessage('Please enter a valid email address.');
        }
      } else {
        setErrorMessage('Please enter your email address.');
      }
    };

    const playSubmit = () => {
      collectEmail();
    }  
//const username = userDetail.username?userDetail.username.split(" ")[0]:"Author"
const username = userDetail ? (userDetail.username ? userDetail.username.split(" ")[0] : "Author") : "Author";
let formattedDate = "";
if(userDetail && userDetail.uploadedAt !== "") {
    const date = new Date(userDetail.uploadedAt);

    // Format the date manually
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    formattedDate = date.toLocaleDateString('en-US', options);
  }
    const actionLabel =
    editAction === 'rename'
      ? activeNavItem === 'Presenters'
        ? 'Rename Presenter'
        : 'Rename Deck'
      : editAction === 'delete'
      ? activeNavItem === 'Presenters'
        ? 'Delete Presenter'
        : 'Delete Deck'
      : editAction === 'feedback'
      ? 'Feedback'
      : editAction === 'customprompt'
      ? 'Custom Prompt'
      : editAction === 'showAlert'
      ? username+' requests your email to continue'
      : '';
  

  const label2 = editAction === 'rename' ?
  (activeNavItem === 'Presenters' ? 'Presenter\'s Name' : 'Deck Name') 
  : editAction === 'delete'
  ?(activeNavItem === 'Presenters' ? 'Are you sure you want to delete this presenter?' : 'Are you sure you want to delete this deck?')
  : editAction === 'showAlert'
  ? ''
  : 'Default Label';
  
    return (    

      //  <div className={`model_box_child ${editAction === 'rename' ? 'dpRenamePop' : (editAction === 'showAlert' || editAction === 'showRestrict') ?"deckPlayDeckP":'dpDeletePop'}`} style={{ width: (editAction === 'feedback' ||  editAction === 'customprompt') ? "752px" : editAction === 'showAlert'?"552px":"440px" }}>
        <div   className={`model_box_child ${editAction === 'rename' ? 'dpRenamePop' : (editAction === 'showAlert' || editAction === 'showRestrict') ? "deckPlayDeckP" : 'dpDeletePop'}`} > 


      <h4>
      
      {editAction === 'showAlert' && (
        <>
      <span style={{textTransform:"none"}}>Made with</span>
      <a href={window.location.origin} target="_blank" rel="noopener noreferrer">
            <img src="../assets/images/Deck_LogoTM.png" alt="" />
            </a>
            </>
      )}     
        {editAction !== 'showAlert' && (
          <>
          <span>{actionLabel}</span>
        <span className="close_model" onClick={handleCloseModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M18 6L6 18M6 6L18 18" stroke={editAction === "feedback" ? "#000000":"#ffffff"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </span>
        </>
    )}
      </h4>
      
      <div className="contentSection">
        {editAction === 'rename' ? (
          <div className="dForm">
            <div className="fldRow">
              <label>{label2}</label>
              <div className="fldwrapfiled">
                <input
                  type="text"
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                  style={{ width: "348px", border: "1px solid white" , color:"gray"}}
                />
                <button className="btn Primary ml-8" onClick={handleUpdate} style={{background:"black", color:"white"}}>
                  Update
                </button>
              </div>
            </div>
          </div>
        ) : (
          editAction === 'feedback' ||  editAction === 'customprompt' ? (

             
            <div className="dForm">
            <div className="fldRow">
              
              {isSuccessFeedback ? (
                <>
              <div class="loaderIcon" style={{textAlign:"center"}}>
               <svg xmlns="http://www.w3.org/2000/svg" width="80" height="81" viewBox="0 0 80 81" fill="none">
                 <path d="M25.0001 40.5L35.0001 50.5L55.0001 30.5M73.3334 40.5C73.3334 58.9095 58.4096 73.8333 40.0001 73.8333C21.5906 73.8333 6.66675 58.9095 6.66675 40.5C6.66675 22.0905 21.5906 7.16666 40.0001 7.16666C58.4096 7.16666 73.3334 22.0905 73.3334 40.5Z" stroke="#067647" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
               </svg>
               <p class="mt-24" style={{color:"black"}}>Thanks for your feedback.</p>
            </div>
            
            </>
            ) : (
              <div className="fldwrapfiled">

            
             <textarea    
          value={updatedText}
        onChange={(e) => setUpdatedText(e.target.value)} 
        rows={10}  // Set the number of rows
        cols={50} 
        style={{
          resize: "none",  // Disable textarea resizing
          
          width:"100%",

          height:"100%",
          padding: "14px" ,
          fontFamily:"Inter",
          fontSize:"16px",
          color:"black",
          fontStyle:"normal",
          fontWeight:"400",
          lineHeight:"24px",
          borderRadius:"8px",
          border:"1px solid #424242",
          background:"#ececec",
          outline: "none"
        }}/>
        <div style={{width:"100%", color:"white",textAlign:"right", marginTop:"10px"}}>
             <button className="btn Primary ml-8" onClick={() => submitFeedback(editAction)}  style={{background:"black", color:"white",textAlign:"right"}}>
                  Update
              </button>
              </div>  
            </div>
 )}

            </div>
            </div>

          ) : editAction === 'showAlert' ? (
            <>
             {!userDetail.isPlanExists ? (
            <>
             <h2 style={{textTransform:"none"}}>{"Ah Well"}</h2>
              <p class="mt-4">
                <span>You've reached the limit of your current plan's quota. To add a new Deck, you can either upgrade your plan or remove an existing one.</span>
                
                
              </p>
            </>
             ):(
             <>
            <h2 style={{textTransform:"none"}}>{userDetail.title}</h2>
            <p class="mt-4">
               <span>{userDetail.username}</span>
               
               {/* {formattedDate && (<span>{formattedDate}</span>)} */}
            </p>
            <div class="dForm">
               <div class="formDeckPlay">
               {showAlert ? (
                <>
                  <label >{actionLabel}</label>
                  <div class="fldwrapfiled">
                     <input
                  type="email"
                  value={email}
                  placeholder="Your email address"
                  onChange={(e) => setEmail(e.target.value)}

                />
                     <button class="btn Primary" onClick={handleEmailSubmit}>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.91663 2.91062C2.91663 2.34411 2.91663 2.06085 3.03475 1.90471C3.13765 1.76868 3.29493 1.68451 3.46519 1.67434C3.66063 1.66267 3.89631 1.8198 4.36768 2.13404L10.5018 6.22342C10.8912 6.48308 11.086 6.61291 11.1538 6.77655C11.2132 6.91961 11.2132 7.0804 11.1538 7.22346C11.086 7.3871 10.8912 7.51693 10.5018 7.77658L4.36768 11.866C3.89631 12.1802 3.66063 12.3373 3.46519 12.3257C3.29493 12.3155 3.13765 12.2313 3.03475 12.0953C2.91663 11.9392 2.91663 11.6559 2.91663 11.0894V2.91062Z" stroke="#181110" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                     <span>Play Deck</span></button>
                    
                  </div>
                  </>
                  ) : (
<div class="fldwrapfiled"><button class="btn Primary" onClick={playSubmit}>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.91663 2.91062C2.91663 2.34411 2.91663 2.06085 3.03475 1.90471C3.13765 1.76868 3.29493 1.68451 3.46519 1.67434C3.66063 1.66267 3.89631 1.8198 4.36768 2.13404L10.5018 6.22342C10.8912 6.48308 11.086 6.61291 11.1538 6.77655C11.2132 6.91961 11.2132 7.0804 11.1538 7.22346C11.086 7.3871 10.8912 7.51693 10.5018 7.77658L4.36768 11.866C3.89631 12.1802 3.66063 12.3373 3.46519 12.3257C3.29493 12.3155 3.13765 12.2313 3.03475 12.0953C2.91663 11.9392 2.91663 11.6559 2.91663 11.0894V2.91062Z" stroke="#181110" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                     <span>Play Deck</span></button></div>
                  )} 
               </div>
            </div>
         </>
             )}
             </>
    ) : editAction === 'showRestrict' ? (
            
      <>
      <h2 style={{textTransform:"none"}}>{"Ah Well"}</h2>
      <p class="mt-4">
         <span>You've reached the limit of your current plan's quota. To add a new Deck, you can either upgrade your plan or remove an existing one.</span>
         
         
      </p>
      <div className="actionLinks jcflexend mt-24">
              <a href="javascript:void(0);" className="btn PrimaryOutline" onClick={handleCloseModal} style={{background:"black", color:"white"}}>
                Cancel
              </a>
              <a href="javascript:void(0);" className="btn Primary" onClick={seePlans} style={{background:"black", color:"white"}}>
                See Plans
              </a>
            </div>
   </>

      
      
          ) : (
          <div className="dpDeletePopContent" >
            <p>{label2}</p>
            <span>You can't undo this.</span>
            <div className="actionLinks jcflexend mt-24">
              <a href="javascript:void(0);" className="btn PrimaryOutline" onClick={handleCloseModal} style={{background:"black", color:"white"}}>
                Cancel
              </a>
              <a href="javascript:void(0);" className="btn Primary" onClick={handleDelete} style={{background:"black", color:"white"}}>
                Delete
              </a>
            </div>
          </div>
          )
        )}
      </div>
    </div>

     

);
};

export default ChildModal;